var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"product.product_statistics_available_for_sale","reloadable":"","resource":"","noClickRow":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.refresh},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('TButtonQuickView',{staticClass:"mx-auto",on:{"click":function($event){return _vm.view(item)}}}),_c('TButtonEdit',{staticClass:"mx-auto",on:{"click":function($event){return _vm.edit(item)}}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SCardProductItems',{attrs:{"item":item.product,"resource":"/sale/goods/products"}})],1)]}},{key:"purchase",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-truncate"},_vm._l((item.purchases),function(purchase){return _c('div',{key:purchase.id},[(
              purchase.items.find(function (x) { return x.product_id == item.product_id; }) &&
              purchase.items.find(function (x) { return x.product_id == item.product_id; })
                .remaining_distributed_quantity > 0
            )?_c('div',{staticClass:"d-flex mb-2 bg-white p-1 rounded"},[_c('TLink',{attrs:{"content":purchase.id,"to":_vm.lodash.getReferenceLink('purchase', purchase.id),"messageOptions":{ bold: true }}}),_c('TMessage',{staticClass:"ml-1",attrs:{"bold":"","content":purchase.buyer ? purchase.buyer.name : purchase.buyer_id,"noTranslate":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(")")]},proxy:true},{key:"prefix",fn:function(){return [_vm._v("(")]},proxy:true}],null,true)}),_c('span',{staticClass:"mx-1"},[_vm._v("-")]),_c('TMessage',{attrs:{"content":"Quantity"}}),_vm._v(": "),_c('TMessageNumber',{staticClass:"ml-1 font-weight-bold",attrs:{"value":purchase.items.find(function (x) { return x.product_id == item.product_id; })
                  .remaining_distributed_quantity}})],1):_vm._e()])}),0)]}},{key:"input",fn:function(ref){
                  var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"size":"h6","content":item.input,"bold":"","noTranslate":""}})],1)]}},{key:"item-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['product_id'],"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'product_id', $event)},_vm.filterChange]}})]},proxy:true}])}),(_vm.productSelected)?_c('SModalUpdateProductSupplier',{attrs:{"show":_vm.showModalEdit,"product_id":_vm.productSelected},on:{"update:show":function($event){_vm.showModalEdit=$event},"updated":_vm.filterChange}}):_vm._e(),(_vm.productViewSelected)?_c('Inventory',{attrs:{"show":_vm.showModalView,"product_id":_vm.productViewSelected},on:{"update:show":function($event){_vm.showModalView=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }