var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TModal',{attrs:{"title":"Purchase inventory","show":_vm.show,"size":"xl"},on:{"update:show":function($event){return _vm.$emit('update:show', $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div')]},proxy:true},{key:"title",fn:function(){return [_c('TMessage',{staticClass:"h5 my-auto",attrs:{"content":"Product","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('span',[_vm._v(": "+_vm._s(_vm.product_id))])]},proxy:true}])})]},proxy:true}])},[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"noPaginate":"","store":"order.purchase_items_inventory","resource":"","noFilter":"","noClickRow":""},scopedSlots:_vm._u([{key:"purchase_id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.purchase_id,"to":_vm.lodash.getReferenceLink('purchase', item.purchase_id),"messageOptions":{ bold: true }}})],1)]}},{key:"buyer_id",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.buyer)?_c('TLink',{attrs:{"content":item.buyer.name,"to":_vm.lodash.getReferenceLink('user', item.buyer.id)}}):_vm._e()],1)]}},{key:"price",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"currency":item.currency_id}})],1)]}},{key:"remaining_distributed_quantity",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"font-weight-bold",attrs:{"value":item.remaining_distributed_quantity}})],1)]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":item.currency_id}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }